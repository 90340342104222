<template>
  <div class="page-container">
    <el-tabs v-model="tabShow">
      <el-tab-pane label="接口文档" name="doc" class="base-pane doc-pane" v-if="false">
        <el-card class="box-card" style="margin-bottom: 20px">
          <el-form label-width="120">
            <el-form-item label="接口名称：" style="margin-bottom: 0px;">
              <span>软件登录</span>
            </el-form-item>
            <el-form-item label="接口地址：" style="margin-bottom: 0px;">
              <span>url+/consumer/ums/loginExe</span>
            </el-form-item>
            <el-form-item label="接口说明：" style="margin-bottom: 0px;">
              <span>使用此接口进行软件登录验证和授权检测</span>
            </el-form-item>
            <el-form-item label="请求方法：" style="margin-bottom: 0px;">
              <span>POST</span>
            </el-form-item>
            <el-form-item label="请求参数：" style="margin-bottom: 0px;">
              <div>
                <el-table :data="doc.data1" border fit>
                  <el-table-column label="参数名" align="center" width="200">
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="是否必填" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.required }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="参数说明" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.des }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
            <el-form-item label="返回参数：" style="margin-bottom: 0px;">
              <div>
                <el-table :data="doc.data2" border fit>
                  <el-table-column label="参数名" align="center" width="200">
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="是否必填" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.required }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="参数说明" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.des }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
            <el-form-item label="返回参数示例：" style="margin-bottom: 0px;">
              <div>
                <textarea v-model="doc.returnText" style="width: 100%;height: 200px"></textarea>
              </div>
            </el-form-item>
            <!--            <el-form-item label="cs：" style="margin-bottom: 0px;">-->
            <!--              <el-button @click="clickTestBtn">测试</el-button>-->
            <!--            </el-form-item>-->
          </el-form>
        </el-card>
        <el-card class="box-card" style="margin-bottom: 20px">
          <el-form label-width="120">
            <el-form-item label="接口名称：" style="margin-bottom: 0px;">
              <span>提交成绩</span>
            </el-form-item>
            <el-form-item label="接口地址：" style="margin-bottom: 0px;">
              <span>url+/consumer/ums/programAddScore</span>
            </el-form-item>
            <el-form-item label="接口说明：" style="margin-bottom: 0px;">
              <span>完成软件全部流程后，使用此接口提交实验结果</span>
            </el-form-item>
            <el-form-item label="请求方法：" style="margin-bottom: 0px;">
              <span>POST</span>
            </el-form-item>
            <el-form-item label="请求参数：" style="margin-bottom: 0px;">
              <div>
                <el-table :data="doc.data3" border fit>
                  <el-table-column label="参数名" align="center" width="200">
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="是否必填" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.required }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="参数说明" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.des }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
            <el-form-item label="返回参数：" style="margin-bottom: 0px;">
              <div>
                <el-table :data="doc.data4" border fit>
                  <el-table-column label="参数名" align="center" width="200">
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="是否必填" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.required }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="参数说明" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.des }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
            <el-form-item label="返回参数示例：" style="margin-bottom: 0px;">
              <div>
                <textarea v-model="doc.returnText2" style="width: 100%;height: 200px"></textarea>
              </div>
            </el-form-item>
            <!--            <el-form-item label="cs：" style="margin-bottom: 0px;">-->
            <!--              <el-button @click="clickTestBtn">测试</el-button>-->
            <!--            </el-form-item>-->
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="接口测试" name="test" class="base-pane doc-pane" v-if="false">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>软件登录</span>
          </div>
          <div class="content">
            <el-form ref="form1" :model="test1" :rules="test1.rule">
              <el-form-item label="学校名称：" prop="schoolname">
                <el-input v-model="test1.schoolname"></el-input>
              </el-form-item>
              <el-form-item label="软件id：" prop="experimentId">
                <el-input v-model="test1.experimentId"></el-input>
              </el-form-item>
              <el-form-item label="用户名（账号）：" prop="account">
                <el-input v-model="test1.account"></el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="password">
                <el-input v-model="test1.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="clickBtn1">登录</el-button>
              </el-form-item>
              <el-form-item label="接口返回：">
                <el-input type="textarea" v-model="test1.result"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>提交成绩</span>
          </div>
          <div class="content">
            <el-form ref="form2" :model="test2" :rules="test2.rule">
              <el-form-item label="软件id：" prop="experimentid">
                <el-input v-model="test2.experimentid"></el-input>
              </el-form-item>
              <!--              <el-form-item label="学期id：" prop="semesterid">-->
              <!--                <el-input v-model="test2.semesterid" placeholder="在教师端-实验课程安排-学期下拉-修改-详情弹窗中复制学期id"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item label="用户id：" prop="userid">
                <el-input v-model="test2.userid"></el-input>
              </el-form-item>
              <el-form-item label="分数：" prop="score">
                <el-input v-model.number="test2.score"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="clickBtn2">提交成绩</el-button>
              </el-form-item>
              <el-form-item label="接口返回：">
                <el-input type="textarea" v-model="test2.result"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="对接案列" name="case" class="base-pane doc-pane">
        <el-card class="box-card" style="color: #666;font-size: 14px">
          <div>本平台和配套的多个虚拟仿真软件都具备接口开放和对接能力，能对接各类型主流平台和跨平台应用。</div>
          <div style="margin-top: 5px">1、根据我方对接文档，其他机构已有的各类型实验可以对接到我方平台。</div>
          <div style="margin-top: 5px">2、根据第三方平台已有的机构，我方平台和软件可以对接到第三方平台</div>
        </el-card>

        <el-card class="box-card" style="color: #666;font-size: 14px;margin-top: 15px;">
          <div slot="header" class="clearfix">
            <span class="card-title">主流平台-国家虚拟仿真实验教学课程共享平台</span>
          </div>
          <div class="card-container">
            <div style="margin-bottom: 5px">
              本司开发的如下课程软件已主动对接到该平台，符合该平台接口标准，并已稳定运行数年。
            </div>
            <div style="margin-bottom: 5px">
              实验空间中的实验都采用网页虚拟仿真实验这种可以跨平台访问的实验方式，本司可以对接多类跨平台应用。
            </div>
            <div><img src="http://resouce.cdzyhd.com/2024-07-31/7c8a9fe0-ceb8-4ba5-81f9-7e91aad62ac9.png" alt=""></div>
            <div><img  style="width: 100%" src="http://resouce.cdzyhd.com/2024-08-05/88f9d117-3ee7-4d34-8e3f-39e496efbeaa.jpg"/></div>
            <div><img  style="width: 100%" src="http://resouce.cdzyhd.com/2024-08-05/aff84565-9868-4a71-a7b0-75ea21c3be9c.jpg"/></div>
          </div>
        </el-card>
        <el-card class="box-card" style="color: #666;font-size: 14px;margin-top: 15px;">
          <div slot="header" class="clearfix">
            <span class="card-title">西南财经大学虚拟仿真实验平台</span>
          </div>
          <div class="card-container">
            <div style="margin-bottom: 5px">本司开发的如下课程软件已成功对接到该学校自助平台，并已稳定持续支持数年。</div>
            <div><img style="width: 100%"
                      src="http://resouce.cdzyhd.com/2024-07-31/1cf1c95a-5ffb-4421-8dbd-4a47cfcfcd1c.png" alt=""></div>
            <div><img  style="width: 100%" src="http://resouce.cdzyhd.com/2024-08-05/88f9d117-3ee7-4d34-8e3f-39e496efbeaa.jpg"/></div>
            <div><img  style="width: 100%" src="http://resouce.cdzyhd.com/2024-08-05/aff84565-9868-4a71-a7b0-75ea21c3be9c.jpg"/></div>
          </div>
        </el-card>
        <el-card class="box-card" style="color: #666;font-size: 14px;margin-top: 15px;">
          <div slot="header" class="clearfix">
            <span class="card-title">主流平台-国家智慧教育公共服务平台</span>
          </div>
          <div class="card-container">
            <div style="margin-bottom: 5px">本司为成都职业技术学院开发的成都战役教学软件，并已成功对接到该平台。</div>
            <div><img style="width: 100%"
                      src="http://resouce.cdzyhd.com/2024-07-31/273a136a-3fc2-4b48-83d0-c0621f5d6acf.png" alt=""></div>
            <div><img  style="width: 100%" src="http://resouce.cdzyhd.com/2024-08-05/2c1549fe-78e8-4890-81d8-170c40553d86.jpg"/></div>
          </div>
        </el-card>
        <el-card class="box-card" style="color: #666;font-size: 14px;margin-top: 15px;">
          <div slot="header" class="clearfix">
            <span class="card-title">四川卫生康复职业学院</span>
          </div>
          <div class="card-container">
            <div style="margin-bottom: 5px">
              本司为四川卫生康复职业学院开发的我的战疫教学软件，根据对方提供的接口，已对接到对方平台。
            </div>
            <div><img style="width: 100%"
                      src="http://resouce.cdzyhd.com/2024-07-31/ef34cb3c-be01-418c-a688-95fc8f53192d.png" alt=""></div>
            <div><img  style="width: 100%" src="http://resouce.cdzyhd.com/2024-08-05/54e6695a-5ee1-458d-8b94-3e9ffdd58973.jpg"/></div>
          </div>
        </el-card>
        <el-card class="box-card" style="color: #666;font-size: 14px;margin-top: 15px;">
          <div slot="header" class="clearfix">
            <span class="card-title">更多案例</span>
          </div>
          <div class="card-container">
            <div style="margin-bottom: 5px">
              持续更新中…………
            </div>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {SchoolModel} from "@/model/exp/SchoolModel";
import {CommonModel} from "@/model/CommonModel";
import {loginExe, programAddScore} from "@/api/exp/TestApi";
import {msg_err, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";

export default {
  name: "teacherCddxOpen",
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      tabShow: "doc",
      doc: {
        data1: [
          {
            name: "schoolname",
            type: "String",
            required: "是",
            des: "学校名称"
          },
          {
            name: "account",
            type: "String",
            required: "是",
            des: "账号"
          },
          {
            name: "password",
            type: "String",
            required: "是",
            des: "密码"
          },
        ],
        data2: [
          {
            name: "code",
            type: "Int",
            required: "是",
            des: "返回状态码"
          },
          {
            name: "msg",
            type: "String",
            required: "是",
            des: "返回信息提示"
          },
          {
            name: "flag",
            type: "Boolean",
            required: "是",
            des: "返回标志，true表示成功，false表示失败"
          },
          {
            name: "data",
            type: "Object",
            required: "是",
            des: "返回参数"
          },
        ],
        returnText: "{\"code\":20000,\"msg\":\"登录成功 \",\"flag\":true,\"data\":{\"unionuserid\":\"123456\",\"userid\":\"123456\",\"username\":\"测试账号\",\"email\":null,\"account\":\"1001\",\"rolename\":\"student\",\"token\":\"`12345\",\"departmentid\":null,\"departmentname\":null,\"semesterid\":\"1\",\"experimentid\":\"44\",\"schoolid\":\"55\",\"schoolname\":\"演示大学\",\"experimentids\":null,\"loginsta\":0,\"ishaveread\":false}}",
        data3: [
          {
            name: "experimentid",
            type: "String",
            required: "是",
            des: "软件ID"
          },
          {
            name: "userid",
            type: "String",
            required: "是",
            des: "用户id"
          },
          {
            name: "score",
            type: "Float",
            required: "是",
            des: "最终分数"
          },
          {
            name: "info",
            type: "Object",
            required: "是",
            des: "其他要提交的信息"
          },
        ],
        data4: [
          {
            name: "code",
            type: "Int",
            required: "是",
            des: "返回状态码"
          },
          {
            name: "msg",
            type: "String",
            required: "是",
            des: "返回信息提示"
          },
          {
            name: "flag",
            type: "Boolean",
            required: "是",
            des: "返回标志，true表示成功，false表示失败"
          },
          {
            name: "data",
            type: "Object",
            required: "是",
            des: "返回参数"
          },
        ],
        returnText2: "{\"code\":20000,\"msg\":\"提交成功 \",\"flag\":true,\"data\":null}",
      },
      schoolList: [],
      test1: {
        schoolname: "演示大学",
        experimentId: "184634833761337344",
        account: "240807",
        password: "123456",
        result: "",
        teacherList: [],
        teacherid: null,
        rule: {
          schoolname: [{required: true, trigger: 'blur', message: '请输入学校名称'}],
          experimentId: [{required: true, trigger: 'blur', message: '请输入软件id'}],
          account: [{required: true, trigger: 'blur', message: '请输入用户名、账号'}],
          password: [{required: true, trigger: 'blur', message: '请输入密码'}],
        }
      },
      test2: {
        experimentid: "",
        semesterid: "",
        userid: "",
        result: "",
        rule: {
          experimentid: [{required: true, trigger: 'blur', message: '请输入实验id'}],
          semesterid: [{required: true, trigger: 'blur', message: '请输入学期id'}],
          userid: [{required: true, trigger: 'blur', message: '请输入用户id'}],
          score: [{required: true, trigger: 'blur', message: '请输入分数'}],
        }
      }
    }
  },
  async mounted() {

  },
  methods: {
    clickTestBtn() {
      console.log(JSON.stringify(this.doc.returnText))
    },
    // 点击实验登录按钮
    async clickBtn1() {
      this.$refs.form1.validate(async valid => {
        if (valid) {
          let [result] = await loginExe({
            schoolname: this.test1.schoolname,
            experimentid: this.test1.experimentId,
            account: this.test1.account,
            password: this.test1.password,
            teacherid: this.test1.teacherid
          })
          this.test1.result = JSON.stringify(result)
          if (result.code === 20000) {
            let resultData = result.data
            // 判断是否是一个学校存在多个学生账号的登录，返回教师列表
            if (resultData.hasOwnProperty("teacherList")) {
              let teacherList = resultData["teacherList"]
              if (teacherList.length > 0) {
                let teacherListArr = CommonModel.generateListFilterOptions("name", "id", teacherList, false)[0]
                this.test1.teacherList = teacherListArr
                msg_err("账号重复，请先在列表中选择教师")
                return
              } else {
                msg_err("服务器错误")
                return false
              }
            }

            msg_success("登录成功")
            this.test2.experimentid = result.data.experimentid
            this.test2.semesterid = result.data.semesterid
            this.test2.userid = result.data.userid
            this.test2.token = result.data.token
            this.$forceUpdate()
          }
        }
      });
    },
    // 点击提交分数按钮
    async clickBtn2() {
      this.$refs.form2.validate(async valid => {
        if (valid) {
          let webToken = sessionStorage.getItem("expToken");
          sessionStorage.setItem("expToken", this.test2.token)
          let [result] = await programAddScore({
            experimentid: this.test2.experimentid,
            semesterid: this.test2.semesterid,
            userid: this.test2.userid,
            score: this.test2.score
          })
          this.test2.result = JSON.stringify(result)
          sessionStorage.setItem("expToken", webToken)
          if (result.code === 20000) {
            msg_success("提交成绩成功")
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.card-title {
  font-weight: bold;
}
</style>
